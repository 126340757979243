import { useMemo } from 'react'
import { LoadingDots } from '@components/ui'
import dynamic from 'next/dynamic'

const Loading = () => (
  <div className="flex h-screen w-full items-center justify-center p-3 text-center">
    <LoadingDots />
  </div>
)

const dynamicProps = {
  loading: Loading,
}

const DefaultPage = dynamic(() => import('templates/Pages/Default'), {
  ...dynamicProps,
})

const Paris = dynamic(() => import('templates/Pages/Paris'), {
  ...dynamicProps,
})

const Pre17c1 = dynamic(() => import('templates/Pages/Pre17c1'), {
  ...dynamicProps,
})

const SpringSale2024 = dynamic(
  () => import('templates/Pages/SpringSale2024/index'),
  {
    ...dynamicProps,
  }
)

const HotdealsNew = dynamic(() => import('templates/Pages/HotdealsNew'), {
  ...dynamicProps,
})

const Preheating = dynamic(() => import('templates/Pages/540WPreheating'), {
  ...dynamicProps,
})

const A1753 = dynamic(() => import('templates/Pages/A1753'), {
  ...dynamicProps,
})

const Living = dynamic(() => import('templates/Pages/Living'), {
  ...dynamicProps,
})

const Affiliate = dynamic(() => import('templates/Pages/Affiliate'), {
  ...dynamicProps,
})

const AnkerDay = dynamic(() => import('templates/Pages/AnkerDay'), {
  ...dynamicProps,
})

const AnkerDayReOrder = dynamic(
  () => import('templates/Pages/AnkerDay/AnkerDayReOrder'),
  {
    ...dynamicProps,
  }
)

const AnkerDayEarlyBird = dynamic(
  () => import('templates/Pages/AnkerDay/EarlyBird'),
  {
    ...dynamicProps,
  }
)

const UpdateFirmware = dynamic(() => import('templates/Pages/UpdateFirmware'), {
  ...dynamicProps,
})

const AnkerDaySale = dynamic(() => import('templates/Pages/AnkerDaySale'), {
  ...dynamicProps,
})

const Solix = dynamic(() => import('templates/Pages/Solix'), {
  ...dynamicProps,
})

const CampingSeries = dynamic(() => import('templates/Pages/CampingSeries'), {
  ...dynamicProps,
})

const FlexSeries = dynamic(() => import('templates/Pages/FlexSeries'), {
  ...dynamicProps,
})

const PrimeTest = dynamic(() => import('templates/Pages/PrimeTest'), {
  ...dynamicProps,
})

const Unbox = dynamic(() => import('./Unbox/Unbox'), {
  ...dynamicProps,
})

const PVCalculator = dynamic(() => import('templates/Pages/PVCalculator'), {
  ...dynamicProps,
})

const Bundle = dynamic(() => import('templates/Pages/Bundle'), {
  ...dynamicProps,
})

const Callback1366 = dynamic(() => import('templates/Pages/Callback1366'), {
  ...dynamicProps,
})

const CallbackForm1366 = dynamic(
  () => import('templates/Pages/CallbackForm1366'),
  {
    ...dynamicProps,
  }
)

const CallbackFormBattery = dynamic(
  () => import('templates/Pages/CallbackFormBattery'),
  {
    ...dynamicProps,
  }
)

const NewYear = dynamic(() => import('templates/Pages/NewYear'), {
  ...dynamicProps,
})

const Cooler = dynamic(() => import('templates/Pages/Cooler'), {
  ...dynamicProps,
})

const PowerhouseWarranty = dynamic(
  () => import('templates/Pages/PowerhouseWarranty'),
  {
    ...dynamicProps,
  }
)

const CoolerCrowdFunding = dynamic(
  () => import('templates/Pages/CoolerCrowdFunding'),
  {
    ...dynamicProps,
  }
)

const ValentinesDay = dynamic(
  () => import('templates/Pages/ValentinesDay/ValentinesDay'),
  {
    ...dynamicProps,
  }
)

const HubB2b = dynamic(() => import('templates/Pages/HubB2B/HubB2B'), {
  ...dynamicProps,
})

const Koa = dynamic(() => import('templates/Pages/Koa'), {
  ...dynamicProps,
})

const BlackFriday = dynamic(() => import('templates/Pages/BlackFriday'), {
  ...dynamicProps,
})

const Christmas = dynamic(() => import('templates/Pages/Christmas/Christmas'), {
  ...dynamicProps,
})

const AppDownload = dynamic(
  () => import('templates/Pages/Download/AppDownload'),
  {
    ...dynamicProps,
  }
)

const Quiz = dynamic(() => import('templates/Pages/Quiz'), {
  ...dynamicProps,
})

const HomeCharger = dynamic(() => import('templates/Pages/HomeCharger'), {
  ...dynamicProps,
})

const FallSale = dynamic(() => import('templates/Pages/FallSale/FallSale'), {
  ...dynamicProps,
})
const F3800 = dynamic(() => import('templates/Pages/F3800/F3800'), {
  ...dynamicProps,
})
const FallSalePrime = dynamic(
  () => import('templates/Pages/FallSalePrime/FallSalePrime'),
  {
    ...dynamicProps,
  }
)
const FallSaleChg = dynamic(
  () => import('templates/Pages/FallSaleChg/FallSaleChg'),
  {
    ...dynamicProps,
  }
)

const PowerStations = dynamic(
  () => import('templates/Pages/PowerHouse/New/PowerHouse'),
  {
    ...dynamicProps,
  }
)

const ActivityTmp = dynamic(() => import('templates/Pages/ActivityTmp'), {
  ...dynamicProps,
})

const SummerLanding = dynamic(() => import('templates/Pages/SummerLanding'), {
  ...dynamicProps,
})

const PowerHouse = dynamic(() => import('templates/Pages/PowerHouse/TestA'), {
  ...dynamicProps,
})

const Search = dynamic(() => import('templates/Pages/Search/Search'), {
  ...dynamicProps,
})

const PowerHouseTest = dynamic(
  () => import('templates/Pages/PowerHouse/TestB'),
  {
    ...dynamicProps,
  }
)

const PowerHouseTestD = dynamic(
  () => import('templates/Pages/PowerHouse/TestD'),
  {
    ...dynamicProps,
  }
)

const PowerHouseTestE = dynamic(
  () => import('templates/Pages/PowerHouse/TestE'),
  {
    ...dynamicProps,
  }
)

const PowerHouseTestF = dynamic(
  () => import('templates/Pages/PowerHouse/TestF'),
  {
    ...dynamicProps,
  }
)

const PowerHouseTestG = dynamic(
  () => import('templates/Pages/PowerHouse/TestG'),
  {
    ...dynamicProps,
  }
)

const Nano65W = dynamic(() => import('templates/Pages/Nano65w/Nano'), {
  ...dynamicProps,
})

const Subscribe = dynamic(() => import('templates/Pages/Subscribe'), {
  ...dynamicProps,
})

const DownloadPage = dynamic(() => import('templates/Pages/Download'), {
  ...dynamicProps,
})

const A1720Code = dynamic(() => import('templates/Pages/A1720Code'), {
  ...dynamicProps,
})
const Ankermake = dynamic(() => import('templates/Pages/Ankermake'), {
  ...dynamicProps,
})
const Series5 = dynamic(() => import('templates/Pages/Series5'), {
  ...dynamicProps,
})
const Series6 = dynamic(() => import('templates/Pages/Series6'), {
  ...dynamicProps,
})
const Series7 = dynamic(() => import('templates/Pages/Series7'), {
  ...dynamicProps,
})

const Home = dynamic(() => import('templates/Pages/Home'), {
  ...dynamicProps,
})

const A1751 = dynamic(() => import('templates/Pages/A1751'), {
  ...dynamicProps,
})

const A9139 = dynamic(() => import('templates/Pages/A9139/TestA/A9139'), {
  ...dynamicProps,
})

const NanoPro = dynamic(() => import('templates/Pages/NanoPro'), {
  ...dynamicProps,
})

const Summer = dynamic(() => import('templates/Pages/Summer'), {
  ...dynamicProps,
})

const CorporatePurchase = dynamic(
  () => import('templates/Pages/CorporatePurchase'),
  {
    ...dynamicProps,
  }
)

const CorporatePurchaseNew = dynamic(
  () => import('templates/Pages/CorporatePurchaseNew'),
  {
    ...dynamicProps,
  }
)

const CorporatePurchaseNew2024 = dynamic(
  () => import('templates/Pages/CorporatePurchaseNew2024'),
  {
    ...dynamicProps,
  }
)

const GanPrime = dynamic(() => import('templates/Pages/GanPrime'), {
  ...dynamicProps,
})

const GanprimeSpecs = dynamic(() => import('templates/Pages/GanprimeSpecs'), {
  ...dynamicProps,
})

const Maggo = dynamic(() => import('templates/Pages/Maggo'), {
  ...dynamicProps,
})

const BucketLandingPage = dynamic(
  () => import('templates/Pages/BucketLandingPage'),
  {
    ...dynamicProps,
  }
)

const F1 = dynamic(() => import('templates/Pages/F1/F1'), {
  ...dynamicProps,
})

const Questionnaire = dynamic(
  () => import('templates/Pages/Questionnaire/Questionnaire'),
  {
    ...dynamicProps,
  }
)

const HomePps = dynamic(() => import('templates/Pages/HomePps'), {
  ...dynamicProps,
})

const PowerhouseDeals = dynamic(
  () => import('templates/Pages/PowerhouseDeals/index'),
  {
    ...dynamicProps,
  }
)

const ChargingScene = dynamic(
  () => import('templates/Pages/ChargingScene/ChargingScene'),
  {
    ...dynamicProps,
  }
)

const Referral = dynamic(() => import('templates/Pages/Referral'), {
  ...dynamicProps,
})
const SolarGenerator = dynamic(
  () => import('templates/Pages/SolarGenerator/index'),
  {
    ...dynamicProps,
  }
)
const PPSAmbassador = dynamic(() => import('templates/Pages/PPSAmbassador'), {
  ...dynamicProps,
})
const SamSung = dynamic(() => import('templates/Pages/SamSung'), {
  ...dynamicProps,
})

const A1780 = dynamic(() => import('templates/Pages/A1780'), {
  ...dynamicProps,
})

const A1780V2 = dynamic(() => import('templates/Pages/A1780V2'), {
  ...dynamicProps,
})
const A1770 = dynamic(() => import('templates/Pages/A1770'), {
  ...dynamicProps,
})
const SolarQuiz = dynamic(() => import('templates/Pages/SolarQuiz'), {
  ...dynamicProps,
})

const Photovoltaic = dynamic(() => import('templates/Pages/Photovoltaic'), {
  ...dynamicProps,
})

const SystemIntroduction = dynamic(
  () => import('templates/Pages/SystemIntroduction'),
  {
    ...dynamicProps,
  }
)

const BlackFriday2023 = dynamic(
  () => import('templates/Pages/2023BlackFriday/2023BlackFriday'),
  {
    ...dynamicProps,
  }
)

const SolarFaqs = dynamic(() => import('templates/Pages/Solar/Faqs'), {
  ...dynamicProps,
})

const PPSDeals = dynamic(() => import('templates/Pages/PPSDeals/PPSDeals'), {
  ...dynamicProps,
})

const SalesPromotion = dynamic(
  () => import('templates/Pages/SalesPromotion/index'),
  {
    ...dynamicProps,
  }
)

const SolarSponsorship = dynamic(
  () => import('templates/Pages/SolarSponsorship/index'),
  {
    ...dynamicProps,
  }
)

const SpringSale = dynamic(() => import('templates/Pages/SpringSale/index'), {
  ...dynamicProps,
})

const ESG = dynamic(() => import('templates/Pages/ESG/index'), {
  ...dynamicProps,
})

const Transformers = dynamic(() => import('templates/Pages/Transformers'), {
  ...dynamicProps,
})

const EsgPromotion = dynamic(() => import('templates/Pages/EsgPromotion'), {
  ...dynamicProps,
})

const SolixHES = dynamic(() => import('templates/Pages/SolixHES'), {
  ...dynamicProps,
})

const FatherDay = dynamic(() => import('templates/Pages/FatherDay/index'), {
  ...dynamicProps,
})

const PPSFatherDay = dynamic(
  () => import('templates/Pages/PPSFatherDay/index'),
  {
    ...dynamicProps,
  }
)

const Credit = dynamic(() => import('templates/Pages/Credit'), {
  ...dynamicProps,
})

const SolarBank = dynamic(() => import('templates/Pages/SolarBank/index'), {
  ...dynamicProps,
})
const SolarBankBundle = dynamic(
  () => import('templates/Pages/SolarBankBundle/index'),
  {
    ...dynamicProps,
  }
)

const Verify = dynamic(() => import('templates/Pages/Verify/index'), {
  ...dynamicProps,
})

const WhereToBuy = dynamic(() => import('templates/Pages/WhereToBuy/index'), {
  ...dynamicProps,
})

const UsbC = dynamic(() => import('templates/Pages/UsbC/index'), {
  ...dynamicProps,
})

const Prime = dynamic(() => import('templates/Pages/Prime/index'), {
  ...dynamicProps,
})

const Referrals = dynamic(() => import('templates/Pages/Referrals/index'), {
  ...dynamicProps,
})

const Livestream = dynamic(() => import('templates/Pages/Livestream/index'), {
  ...dynamicProps,
})

const AnkerPrime = dynamic(() => import('templates/Pages/AnkerPrime/index'), {
  ...dynamicProps,
})

const RMA = dynamic(() => import('templates/Pages/RMA/index'), {
  ...dynamicProps,
})

const Nano = dynamic(() => import('templates/Pages/Nano/index'), {
  ...dynamicProps,
})

const CashBack = dynamic(() => import('templates/Pages/CashBack/CashBack'), {
  ...dynamicProps,
})

const CashBackCharge = dynamic(
  () => import('templates/Pages/CashBack/CashBackCharge'),
  {
    ...dynamicProps,
  }
)

const CashBackCampaign = dynamic(
  () => import('templates/Pages/CashBackCampaign/CashBackCharge'),
  {
    ...dynamicProps,
  }
)

const CashBackPPS = dynamic(
  () => import('templates/Pages/CashBack/CashBackPPS'),
  {
    ...dynamicProps,
  }
)

const ProductRecycling = dynamic(
  () => import('templates/Pages/ProductRecycling/index'),
  {
    ...dynamicProps,
  }
)

const A1761 = dynamic(() => import('templates/Pages/A1761/index'), {
  ...dynamicProps,
})
const A1761Scene = dynamic(() => import('templates/Pages/A1761Scene/index'), {
  ...dynamicProps,
})
const FullPd = dynamic(() => import('templates/Pages/FullPd/index'), {
  ...dynamicProps,
})
const BlackFridayChg2023 = dynamic(
  () => import('templates/Pages/2023BlackFridayChg/2023BlackFridayChg'),
  {
    ...dynamicProps,
  }
)
const BlackFridayChgFormal2023 = dynamic(
  () =>
    import('templates/Pages/2023BlackFridayChgFormal/2023BlackFridayChgFormal'),
  {
    ...dynamicProps,
  }
)
const TravelEssentials = dynamic(
  () => import('templates/Pages/TravelEssentials/index'),
  {
    ...dynamicProps,
  }
)
const TravelEssentialsOld = dynamic(
  () => import('templates/Pages/TravelEssentials_old/index'),
  {
    ...dynamicProps,
  }
)
const HesBrandPage = dynamic(
  () => import('templates/Pages/HesBrandPage/index'),
  {
    ...dynamicProps,
  }
)

const Ambassador = dynamic(() => import('templates/Pages/Ambassador/index'), {
  ...dynamicProps,
})

const Gifting = dynamic(() => import('templates/Pages/Gifting/index'), {
  ...dynamicProps,
})

const Recycling = dynamic(() => import('templates/Pages/Recycling/index'), {
  ...dynamicProps,
})

const W43DDL = dynamic(() => import('templates/Pages/W43DDL/index'), {
  ...dynamicProps,
})

const Weihnachten = dynamic(() => import('templates/Pages/Weihnachten/index'), {
  ...dynamicProps,
})

const ChristmasDay2023 = dynamic(
  () => import('templates/Pages/2023ChristmasDay/index'),
  {
    ...dynamicProps,
  }
)
const ChristmasDay2023Au = dynamic(
  () => import('templates/Pages/2023ChristmasDayAu/index'),
  {
    ...dynamicProps,
  }
)
const A1790 = dynamic(() => import('templates/Pages/A1790/A1790'), {
  ...dynamicProps,
})
const Installer = dynamic(() => import('templates/Pages/Installer/index'), {
  ...dynamicProps,
})

const X1LangdingPage = dynamic(
  () => import('templates/Pages/X1LangdingPage/index'),
  {
    ...dynamicProps,
  }
)

const HesForm = dynamic(() => import('templates/Pages/HesForm/HesForm'), {
  ...dynamicProps,
})

const ParallelOperation = dynamic(
  () => import('templates/Pages/ParallelOperation'),
  {
    ...dynamicProps,
  }
)

const NewBalconyProducts = dynamic(
  () => import('templates/Pages/NewBalconyProducts/index'),
  {
    ...dynamicProps,
  }
)

const HappyNewYear2023 = dynamic(
  () => import('templates/Pages/2023HappyNewYear/index'),
  {
    ...dynamicProps,
  }
)

const C800 = dynamic(() => import('templates/Pages/C800/index'), {
  ...dynamicProps,
})
const MppCampaign2023 = dynamic(
  () => import('templates/Pages/2023MppCampaign/index'),
  {
    ...dynamicProps,
  }
)
const MemberGift = dynamic(() => import('templates/Pages/MemberGift/index'), {
  ...dynamicProps,
})

const ValentinesDay2024 = dynamic(
  () => import('templates/Pages/2024ValentinesDay/index'),
  {
    ...dynamicProps,
  }
)

const OutdoorCampaign2024 = dynamic(
  () => import('templates/Pages/2024OutdoorCampaign/index'),
  {
    ...dynamicProps,
  }
)
const A17b1 = dynamic(() => import('templates/Pages/A17b1/index'), {
  ...dynamicProps,
})

const HubDocking2024 = dynamic(
  () => import('templates/Pages/2024HubDocking/index'),
  {
    ...dynamicProps,
  }
)
const Deals = dynamic(() => import('templates/Pages/Deals'), {
  ...dynamicProps,
})
const SeoTopic = dynamic(() => import('templates/Pages/SeoTopic/index'), {
  ...dynamicProps,
})

const SolixEarthDay2024 = dynamic(
  () => import('templates/Pages/2024SolixEarthDay/index'),
  {
    ...dynamicProps,
  }
)

const SolixSummerSales2024 = dynamic(
  () => import('templates/Pages/2024SolixSummerSales/index'),
  {
    ...dynamicProps,
  }
)

const EarthDay = dynamic(() => import('templates/Pages/2024EarthDay/index'), {
  ...dynamicProps,
})

const AuEarthDay = dynamic(
  () => import('templates/Pages/2024AuEarthDay/index'),
  {
    ...dynamicProps,
  }
)
const BuiltIn = dynamic(() => import('templates/Pages/BuiltIn/index'), {
  ...dynamicProps,
})

const OnTheGoCampaign2024 = dynamic(
  () => import('templates/Pages/2024OnTheGoCampaign/index'),
  {
    ...dynamicProps,
  }
)

const OnTheGoCampaign2024AU = dynamic(
  () => import('templates/Pages/2024OnTheGoCampaignAU/index'),
  {
    ...dynamicProps,
  }
)

const MemorialDay2024 = dynamic(
  () => import('templates/Pages/2024MemorialDay/index'),
  {
    ...dynamicProps,
  }
)

const SolarCampaign = dynamic(
  () => import('templates/Pages/SolarCampaign/index'),
  {
    ...dynamicProps,
  }
)
const A17c1 = dynamic(() => import('templates/Pages/A17c1/A17c1'), {
  ...dynamicProps,
})

const Promotional = dynamic(() => import('templates/Pages/Promotional/index'), {
  ...dynamicProps,
})

const SEO2024 = dynamic(() => import('templates/Pages/SEO2024/index'), {
  ...dynamicProps,
})

const ChargerBrand = dynamic(
  () => import('templates/Pages/2024ChargerBrand/index'),
  {
    ...dynamicProps,
  }
)

const PowerOutage = dynamic(() => import('templates/Pages/PowerOutage/index'), {
  ...dynamicProps,
})

const SolixPrimeDay2024 = dynamic(
  () => import('templates/Pages/2024PrimeDay/index'),
  {
    ...dynamicProps,
  }
)
const Voltaic = dynamic(() => import('templates/Pages/Voltaic/Voltaic'), {
  ...dynamicProps,
})
const VoltaicSence = dynamic(
  () => import('templates/Pages/VoltaicSence/VoltaicSence'),
  {
    ...dynamicProps,
  }
)

const SplineRender = dynamic(() => import('templates/Pages/SplineRender'), {
  ...dynamicProps,
  ssr: false,
})

const PrimePreSale = dynamic(
  () => import('templates/Pages/Prime2024/PrimePreSale'),
  {
    ...dynamicProps,
  }
)
const Prime2024 = dynamic(() => import('templates/Pages/Prime2024/Prime2024'), {
  ...dynamicProps,
})

const A1726 = dynamic(() => import('templates/Pages/A1726/index'), {
  ...dynamicProps,
})
const ShellMembrane = dynamic(
  () => import('templates/Pages/ShellMembrane/ShellMembrane'),
  {
    ...dynamicProps,
  }
)

const BlonyUserStory = dynamic(
  () => import('templates/Pages/BlonyUserStory/BlonyUserStory'),
  {
    ...dynamicProps,
  }
)
const SolixFans = dynamic(() => import('templates/Pages/SolixFans/SolixFans'), {
  ...dynamicProps,
})

const ApartmentHES = dynamic(() => import('templates/Pages/ApartmentHES'), {
  ...dynamicProps,
})
const SolixFansFinal = dynamic(
  () => import('templates/Pages/SolixFans/SolixFansFinal'),
  {
    ...dynamicProps,
  }
)

const Pages = (props) => {
  const { slug, metafields } = props

  const targetTemp = (target, tempMap) => {
    const temp = tempMap.find((v) => {
      return v.rules && v.rules.some((fn) => fn(target))
    })
    return (
      temp?.templates || tempMap.find((v) => v.name === 'Default')?.templates
    )
  }

  const template = useMemo(() => {
    const target = metafields?.template || slug
    return targetTemp(target, [
      {
        name: 'Default',
        templates: <DefaultPage {...props} />,
      },
      {
        name: 'Paris',
        templates: <Paris {...props} />,
        rules: [(temp) => temp === '/paris-2024'],
      },
      {
        name: 'Deals',
        templates: <Deals {...props} />,
        rules: [(temp) => temp === 'deals'],
      },
      {
        name: 'A1726',
        templates: <A1726 {...props} />,
        rules: [(target) => target === 'a1726'],
      },
      {
        name: 'Prime2024',
        templates: <Prime2024 {...props} />,
        rules: [(target) => target === '/prime-2024'],
      },
      {
        name: 'PrimePreSale',
        templates: <PrimePreSale {...props} />,
        rules: [(target) => target === 'prime-pre-sale-2024'],
      },
      {
        name: 'SpringSale2024',
        templates: <SpringSale2024 {...props} />,
        rules: [(temp) => temp === '/springsale2024'],
      },
      {
        name: 'ValentinesDay2024',
        templates: <ValentinesDay2024 {...props} />,
        rules: [(temp) => temp === '/valentinesday2024'],
      },
      {
        name: 'ParallelOperation',
        templates: <ParallelOperation {...props} />,
        rules: [(temp) => temp === '/parallel-operation'],
      },
      {
        name: 'weihnachten',
        templates: <Weihnachten {...props} />,
        rules: [(temp) => temp === '/weihnachten'],
      },
      {
        name: 'preheating',
        templates: <Preheating {...props} />,
        rules: [(temp) => temp === '/preheating'],
      },
      {
        name: 'C800',
        templates: <C800 {...props} />,
        rules: [(temp) => temp === '/c800'],
      },
      {
        name: 'A1753',
        templates: <A1753 {...props} />,
        rules: [(temp) => temp === '/a1753'],
      },
      {
        name: '2023HappyNewYear',
        templates: <HappyNewYear2023 {...props} />,
        rules: [(temp) => temp === '/happy-new-year-2023'],
      },
      {
        name: 'NewBalconyProducts',
        templates: <NewBalconyProducts {...props} />,
        rules: [(temp) => temp === '/new-balcony-products'],
      },
      {
        name: 'W43DDL',
        templates: <W43DDL {...props} />,
        rules: [(target) => target === '/w43ddl'],
      },
      {
        name: 'Solix',
        templates: <Solix {...props} />,
        rules: [
          (target) => target === '/ankersolix',
          (target) => target === '/anker-solix/power-solutions',
        ],
      },
      {
        name: 'BlackFriday2023',
        templates: <BlackFriday2023 {...props} />,
        rules: [
          (target) => target === '/black-friday-campaign',
          (target) => target === '/anker-solix/black-friday-pps',
        ],
      },
      {
        name: 'CashBack',
        templates: <CashBack {...props} />,
        rules: [
          (target) => target === '/cashback',
          (target) =>
            target === '/anker-solix/balkonkraftwerk-mit-speicher-angebote-bms',
        ],
      },
      {
        name: 'CashBackCampaign',
        templates: <CashBackCampaign {...props} />,
        rules: [(target) => target === '/autumn-sale-2024'],
      },
      {
        name: 'CashBackCharge',
        templates: <CashBackCharge {...props} />,
        rules: [
          (target) => target === '/cashback-charge',
          (target) => target === '/back-to-school-chg',
        ],
      },
      {
        name: 'product-recycling',
        templates: <ProductRecycling {...props} />,
        rules: [(target) => target === '/product-recycling'],
      },
      {
        name: 'CashBackPPS',
        templates: <CashBackPPS {...props} />,
        rules: [
          (target) => target === '/cashback-pps',
          (target) => target === '/powerhouse-deals-pps',
        ],
      },
      {
        name: 'PrimeTest',
        templates: <PrimeTest {...props} />,
        rules: [(target) => target?.includes('prime-test')],
      },
      {
        name: 'HomeCharger',
        templates: <HomeCharger {...props} />,
        rules: [(target) => target?.includes('home-charger')],
      },
      {
        name: 'HomePPS',
        templates: <HomePps {...props} />,
        rules: [(target) => target?.includes('home-pps')],
      },
      {
        name: 'Home',
        templates: <Home {...props} />,
        rules: [(target) => target === 'home'],
      },
      {
        name: 'Home',
        templates: <Living {...props} />,
        rules: [(target) => target === '/living'],
      },
      {
        name: 'HotdealsNew',
        templates: <HotdealsNew {...props} />,
        rules: [(target) => target === 'hotdeals'],
      },
      {
        name: 'Quiz',
        templates: <Quiz {...props} />,
        rules: [(target) => target?.includes('quiz_temp')],
      },
      {
        name: 'PowerhouseWarranty',
        templates: <PowerhouseWarranty {...props} />,
        rules: [
          (target) =>
            target?.includes('powerhouse-warranty') ||
            target?.includes('warranty-registration'),
        ],
      },
      {
        name: 'ActivityTmp',
        templates: <ActivityTmp {...props} />,
        rules: [(target) => target?.includes('activity_temp')],
      },
      {
        name: 'A1720Code',
        templates: <A1720Code {...props} />,
        rules: [(target) => target?.includes('a1720code')],
      },
      {
        name: 'ChargingScene',
        templates: <ChargingScene {...props} />,
        rules: [
          (temp) => temp.includes('power-outdoor'),
          (temp) => temp.includes('charging-scene'),
          (temp) => temp.includes('strom-draussen'),
          (temp) => temp.includes('home-backup-power'),
          (temp) => temp.includes('stromreserve-zuhause'),
        ],
      },
      {
        name: 'PowerStations',
        templates: <PowerStations {...props} />,
        rules: [
          (temp) => temp && temp === '/power-stations',
          (temp) => temp && temp.includes('portable-power-stations'),
        ],
      },
      {
        name: 'nano-pro',
        templates: <NanoPro {...props} />,
        rules: [(current) => current && current?.includes('nano-pro')],
      },
      {
        name: 'PowerHouse',
        templates: <PowerHouse {...props} />,
        rules: [(temp) => temp && temp === '/powerhouse_757'],
      },
      {
        name: 'powerhouse_757_v2',
        templates: <PowerHouseTest {...props} />,
        rules: [(temp) => temp && temp === '/powerhouse_757_v2'],
      },
      {
        name: 'powerhouse_757_v4',
        templates: <PowerHouseTestD {...props} />,
        rules: [(temp) => temp && temp === '/powerhouse_757_v4'],
      },
      {
        name: 'powerhouse_757_v5',
        templates: <PowerHouseTestE {...props} />,
        rules: [(temp) => temp && temp === '/powerhouse_757_v5'],
      },
      {
        name: 'powerhouse_757_v6',
        templates: <PowerHouseTestF {...props} />,
        rules: [(temp) => temp && temp === '/powerhouse_757_v6'],
      },
      {
        name: 'powerhouse_757_boa',
        templates: <PowerHouseTestG {...props} />,
        rules: [(temp) => temp && temp === '/powerhouse_757_boa'],
      },
      {
        name: 'nano_65w',
        templates: <Nano65W {...props} />,
        rules: [
          (temp) => temp && temp.includes('735charger65w'),
          (temp) => temp && temp.includes('735charger65w-c-boa'),
          (temp) => temp && temp.includes('735charger65w-o-boa'),
          (temp) => temp && temp.includes('735charger65w-a-boa'),
          (temp) => temp && temp.includes('ptengine-test'),
        ],
      },
      {
        name: 'coming-soon',
        templates: <Subscribe {...props} />,
        rules: [(temp) => temp && temp.includes('coming-soon')],
      },
      {
        name: 'Download',
        templates: <DownloadPage {...props} />,
        rules: [(target) => target === '/download'],
      },
      {
        name: 'Ankermake',
        templates: <Ankermake {...props} />,
        rules: [(target) => target?.includes('ankermake')],
      },
      {
        name: 'A1751',
        templates: <A1751 {...props} />,
        rules: [(target) => target?.includes('a1751')],
      },
      {
        name: 'a9139',
        templates: <A9139 {...props} />,
        rules: [(target) => target?.includes('power-strip')],
      },
      {
        name: 'Series5',
        templates: <Series5 {...props} />,
        rules: [(target) => target.includes('series5')],
      },
      {
        name: 'Series6',
        templates: <Series6 {...props} />,
        rules: [(target) => target.includes('series6')],
      },
      {
        name: 'Series7',
        templates: <Series7 {...props} />,
        rules: [(target) => target.includes('series7')],
      },
      {
        name: 'GanPrime',
        templates: <GanPrime {...props} />,
        rules: [
          (temp) =>
            (temp && temp == '/ganprime') || temp.includes('ganprime_boa'),
        ],
      },
      {
        name: 'ganprime-specs',
        templates: <GanprimeSpecs {...props} />,
        rules: [
          (temp) =>
            (temp && temp === '/ganprime-specs') ||
            temp === '/ganprime-specs_boa',
        ],
      },
      {
        name: 'search',
        templates: <Search {...props} />,
        rules: [(target) => target.includes('search')],
      },
      {
        name: 'summer',
        templates: <Summer {...props} />,
        rules: [(temp) => temp && temp === '/summer'],
      },
      {
        name: 'maggo',
        templates: <Maggo {...props} />,
        rules: [(current) => current && current?.includes('maggo')],
      },
      {
        name: 'corporate-new',
        templates: <CorporatePurchaseNew {...props} />,
        rules: [(temp) => temp && temp === '/corporate-new'],
      },
      {
        name: 'corporate-new-2024',
        templates: <CorporatePurchaseNew2024 {...props} />,
        rules: [(temp) => temp && temp === '/corporate-new-2024'],
      },
      {
        name: 'corporate',
        templates: <CorporatePurchase {...props} />,
        rules: [(current) => current && current?.includes('corporate')],
      },
      {
        name: 'nano3',
        templates: <BucketLandingPage {...props} />,
        rules: [(temp) => temp.includes('nano3')],
      },
      {
        name: 'nano3-boa',
        templates: <BucketLandingPage {...props} />,
        rules: [(temp) => temp.includes('nano3-boa')],
      },
      {
        name: 'f1',
        templates: <F1 {...props} />,
        rules: [
          (temp) => temp.includes('f1') || temp.includes('cota-formula1'),
        ],
      },
      {
        name: 'fall-sale-chg',
        templates: <FallSaleChg {...props} />,
        rules: [(temp) => temp.includes('fall-sale-chg')],
      },
      {
        name: 'fall-sale',
        templates: <FallSale {...props} />,
        rules: [(temp) => temp.includes('fall-sale')],
      },
      {
        name: 'FallSalePrime',
        templates: <FallSalePrime {...props} />,
        rules: [(temp) => temp.includes('FallSalePrime')],
      },
      {
        name: 'questionnaire',
        templates: <Questionnaire {...props} />,
        rules: [(temp) => temp.includes('questionnaire')],
      },
      {
        name: 'app-download',
        templates: <AppDownload {...props} />,
        rules: [(temp) => temp.includes('app-download')],
      },
      {
        name: 'BlackFridayChg2023',
        templates: <BlackFridayChg2023 {...props} />,
        rules: [(temp) => temp.includes('black-friday-sale-2023')],
      },
      {
        name: '2023BlackFridayChgFormal',
        templates: <BlackFridayChgFormal2023 {...props} />,
        rules: [(temp) => temp === 'black-friday-sale-formal-2023'],
      },
      {
        name: 'black-friday-sale',
        templates: <BlackFriday {...props} />,
        rules: [(temp) => temp && temp === '/black-friday-sale'],
      },
      {
        name: 'christmas-sale',
        templates: <Christmas {...props} />,
        rules: [(temp) => temp && temp === '/christmas-sale'],
      },
      {
        name: 'pps-father-day',
        templates: <PPSFatherDay {...props} />,
        rules: [(temp) => temp.includes('powerhouse-deals-0608')],
      },
      {
        name: 'powerhouse-deals',
        templates: <SummerLanding {...props} />,
        rules: [(temp) => temp && temp === 'powerhouse-deals-0525'],
      },
      {
        name: 'powerhouse-deals',
        templates: <PowerhouseDeals {...props} />,
        rules: [(temp) => temp.includes('powerhouse-deals')],
      },
      {
        name: 'Referrals',
        templates: <Referrals {...props} />,
        rules: [(target) => target === 'referrals-0703'],
      },
      {
        name: 'referral',
        templates: <Referral {...props} />,
        rules: [
          (temp) => temp.includes('referral'),
          (temp) => temp.includes('empfehlung'),
          (temp) => temp.includes('balkonkraftwerk_empfehlung'),
        ],
      },
      {
        name: 'koa',
        templates: <Koa {...props} />,
        rules: [(temp) => temp.includes('koa-rewards')],
      },
      {
        name: 'solar-generator',
        templates: <SolarGenerator {...props} />,
        rules: [
          (temp) => temp.includes('solar-generator'),
          (temp) => temp.includes('solargenerator'),
        ],
      },
      {
        name: 'journeys-with-ambassadors',
        templates: <PPSAmbassador {...props} />,
        rules: [(temp) => temp.includes('journeys-with-ambassadors')],
      },
      {
        name: 'hub-and-docking-station-for-business',
        templates: <HubB2b {...props} />,
        rules: [
          (temp) => temp.includes('hub-and-docking-station-for-business'),
        ],
      },
      {
        name: 'update-firmware',
        templates: <UpdateFirmware {...props} />,
        rules: [(temp) => temp.includes('update-firmware')],
      },
      {
        name: 'ace-charger',
        templates: <SamSung {...props} />,
        rules: [
          (temp) => temp.includes('samsung'),
          (temp) => temp.includes('ace-charger'),
          (temp) => temp && temp === '/ace-charger-boa',
        ],
      },
      {
        name: 'new-year',
        templates: <NewYear {...props} />,
        rules: [(temp) => temp.includes('new-year')],
      },
      {
        name: 'pv-calculator',
        templates: <PVCalculator {...props} />,
        rules: [
          (temp) => temp.includes('balkonkraftwerk_stromkosten'),
          (temp) => temp.includes('balcony-power-plant-electricity-costs'),
          (temp) => temp.includes('rentabilite-panneau-solaire'),
        ],
      },
      {
        name: 'valentines-day-gifts',
        templates: <ValentinesDay {...props} />,
        rules: [(temp) => temp.includes('valentines')],
      },
      {
        name: 'call-back-1366-form',
        templates: <CallbackForm1366 {...props} />,
        rules: [
          (temp) => temp.includes('callbackform'),
          (temp) => temp.includes('a1366-recall-form'),
        ],
      },
      {
        name: 'call-back-1366-battery',
        templates: <CallbackFormBattery {...props} />,
        rules: [(temp) => temp.includes('call-back-1366-battery')],
      },
      {
        name: 'call-back-1366',
        templates: <Callback1366 {...props} />,
        rules: [
          (temp) => temp.includes('call-back'),
          (temp) => temp.includes('a1366-recall'),
        ],
      },
      {
        name: 'a1780v2',
        templates: <A1780V2 {...props} />,
        rules: [(temp) => temp.includes('A1780V2')],
      },
      {
        name: 'balkonkraftwerk-produkteinfuehrung',
        templates: <SystemIntroduction {...props} />,
        rules: [
          (temp) => temp.includes('balkonkraftwerk-produkteinfuehrung'),
          (temp) => temp.includes('balcony-power-plant-product-launch'),
          (temp) => temp.includes('kit-panneau-solaire-produit-description'),
        ],
      },
      {
        name: 'balkonkraftwerk_erster_eindruck',
        templates: <Photovoltaic {...props} />,
        rules: [
          (temp) => temp.includes('balkonkraftwerk_erster_eindruck'),
          (temp) => temp.includes('balcony-power-plant-first-impression'),
          (temp) => temp === '/anker-solix/kit-panneau-solaire',
        ],
      },
      {
        name: 'balkonkraftwerk_zuschuesse_regional',
        templates: <SolarSponsorship {...props} />,
        rules: [(temp) => temp.includes('balkonkraftwerk_zuschuesse_regional')],
      },
      {
        name: 'a1780',
        templates: <A1780 {...props} />,
        rules: [(temp) => temp.includes('A1780')],
      },
      {
        name: 'a1770',
        templates: <A1770 {...props} />,
        rules: [(temp) => temp.includes('A1770')],
      },
      {
        name: 'solar-quiz',
        templates: <SolarQuiz {...props} />,
        rules: [
          (temp) => temp.includes('preparation-quiz'),
          (temp) => temp.includes('balkonkraftwerk_quiz'),
          (temp) => temp.includes('balcony-power-plant-quiz'),
          (temp) => temp.includes('installation-photovoltaique-quiz'),
        ],
      },
      {
        name: 'balkonkraftwerk_kundenservice',
        templates: <SolarFaqs {...props} />,
        rules: [
          (temp) => temp.includes('balkonkraftwerk_kundenservice'),
          (temp) => temp.includes('balcony-power-plant-customer-service'),
          (temp) => temp.includes('faq-sur-kit-panneau-solaire'),
        ],
      },
      {
        name: 'PPSDeals',
        templates: <PPSDeals {...props} />,
        rules: [(temp) => temp.includes('pps-deals')],
      },
      {
        name: 'SalesPromotion',
        templates: <SalesPromotion {...props} />,
        rules: [(temp) => temp.includes('sales-promotion')],
      },
      {
        name: 'SpringSale',
        templates: <SpringSale {...props} />,
        rules: [(temp) => temp.includes('spring-sale')],
      },
      {
        name: 'coolerCrowdFunding',
        templates: <CoolerCrowdFunding {...props} />,
        rules: [
          (temp) =>
            temp === '/everfrost-powered-cooler' ||
            temp === '/everfrost-akku-kuehlbox',
        ],
      },
      {
        name: 'ESG',
        templates: <ESG {...props} />,
        rules: [(temp) => temp === '/esg'],
      },
      {
        name: 'Transformers',
        templates: <Transformers {...props} />,
        rules: [(temp) => temp.includes('transformers')],
      },
      {
        name: 'CampingSeries',
        templates: <CampingSeries {...props} />,
        rules: [
          (temp) => temp.includes('campingseries'),
          (temp) => temp.includes('camping-series'),
          (temp) => temp.includes('camping-serie'),
        ],
      },
      {
        name: 'FlexSeries',
        templates: <FlexSeries {...props} />,
        rules: [
          (temp) => temp.includes('flexseries'),
          (temp) => temp.includes('flex-series'),
          (temp) => temp.includes('flex-serie'),
        ],
      },
      {
        name: 'bundle',
        templates: <Bundle {...props} />,
        rules: [
          (temp) => temp.includes('build-your-own-system'),
          (temp) => temp.includes('build-your-own-anker-charging-system'),
        ],
      },
      {
        name: 'unBox',
        templates: <Unbox {...props} />,
        rules: [(temp) => temp.includes('unbox')],
      },
      {
        name: 'fatherDay',
        templates: <FatherDay {...props} />,
        rules: [(temp) => temp.includes('fathers-day-sale')],
      },
      {
        name: 'ankerDay',
        templates: <AnkerDay {...props} />,
        rules: [
          (temp) => temp.includes('members-exclusive-sale'),
          (temp) => temp === '/anker-solix/fan-week',
        ],
      },
      {
        name: 'ankerDayReOrder',
        templates: <AnkerDayReOrder {...props} />,
        rules: [(temp) => temp.includes('ankerDayReOrder')],
      },
      {
        name: 'ankerDayEarlyBird',
        templates: <AnkerDayEarlyBird {...props} />,
        rules: [(temp) => temp === '/anker-solix/prime-week-sale'],
      },
      {
        name: 'ankerDaySale',
        templates: <AnkerDaySale {...props} />,
        rules: [(temp) => temp === '/solde-dete'],
      },
      {
        name: 'EsgPromotion',
        templates: <EsgPromotion {...props} />,
        rules: [
          (temp) =>
            temp === '/esg_promotion' ||
            temp === '/esg_promotion_ankerpower' ||
            temp === '/earthdaysale' ||
            temp === '/earth-day-angebote' ||
            temp === '/earth-day-pps' ||
            temp === '/earth-day-powerstationen',
        ],
      },
      {
        name: 'SolixHES',
        templates: <SolixHES {...props} />,
        rules: [(temp) => temp.includes('solixhes')],
      },
      {
        name: 'SolixHES',
        templates: <SolixHES {...props} />,
        rules: [
          (target) => target === '/solixhes',
          (target) => target === '/anker-solix/home-energy-solutions',
          (target) => target === '/anker-solix/home-energy-ladeloesungen',
        ],
      },
      {
        name: 'affiliate',
        templates: <Affiliate {...props} />,
        rules: [(target) => target === '/become-an-affiliate'],
      },
      {
        name: 'whereToBuy',
        templates: <WhereToBuy {...props} />,
        rules: [(target) => target === '/wheretobuy'],
      },
      {
        name: 'AnkerCredit',
        templates: <Credit {...props} />,
        rules: [(temp) => temp.includes('ankercredit')],
      },
      {
        name: 'verify',
        templates: <Verify {...props} />,
        rules: [(target) => target === '/verify'],
      },
      {
        name: 'SolarBank',
        templates: <SolarBank {...props} />,
        rules: [
          (target) => target === '/solarbank',
          (target) =>
            target ===
            '/anker-solix/balcony-solar-storage-system-solarbank-a17c0',
          (target) =>
            target ===
            '/anker-solix/balkonkraftwerk-mit-speicher-solarbank-a17c0',
        ],
      },
      {
        name: 'UsbC',
        templates: <UsbC {...props} />,
        rules: [(temp) => temp.includes('usb-c-charger')],
      },
      {
        name: 'AnkerPrime',
        templates: <AnkerPrime {...props} />,
        rules: [(temp) => temp.includes('/ankerprime')],
      },
      {
        name: 'SolixPrimeDay2024',
        templates: <SolixPrimeDay2024 {...props} />,
        rules: [(temp) => temp === '/solix-prime-day-2024'],
      },
      {
        name: 'prime',
        templates: <Prime {...props} />,
        rules: [(temp) => temp.includes('prime')],
      },
      {
        name: 'SolarBankBundle',
        templates: <SolarBankBundle {...props} />,
        rules: [
          (target) => target === '/anker-solix/solarbank-bundle',
          (target) => target === '/anker-solix/balkonkraftwerk-mit-speicher',
        ],
      },
      {
        name: 'livestream',
        templates: <Livestream {...props} />,
        rules: [(target) => target === '/livestream'],
      },
      {
        name: 'rma',
        templates: <RMA {...props} />,
        rules: [(temp) => temp.includes('exchange')],
      },
      {
        name: 'Nano',
        templates: <Nano {...props} />,
        rules: [(temp) => temp.includes('usb-c-charging')],
      },
      {
        name: 'f3800',
        templates: <F3800 {...props} />,
        rules: [(temp) => temp.includes('/f3800')],
      },
      {
        name: 'cooler',
        templates: <Cooler {...props} />,
        rules: [(temp) => temp.includes('/cooler')],
      },
      {
        name: 'ambassador',
        templates: <Ambassador {...props} />,
        rules: [(temp) => temp.includes('/ambassador')],
      },
      {
        name: 'A1761Scene',
        templates: <A1761Scene {...props} />,
        rules: [(temp) => temp === '/anker-solix/a1761-scene'],
      },
      {
        name: 'A1761',
        templates: <A1761 {...props} />,
        rules: [(temp) => temp.includes('a1761')],
      },
      {
        name: 'FullPd',
        templates: <FullPd {...props} />,
        rules: [(temp) => temp.includes('full-pd')],
      },
      {
        name: 'Gifting',
        templates: <Gifting {...props} />,
        rules: [(temp) => temp === '/gifting'],
      },
      {
        name: 'recycling',
        templates: <Recycling {...props} />,
        rules: [(temp) => temp === '/recycling'],
      },
      {
        name: 'TravelEssentials',
        templates: <TravelEssentials {...props} />,
        rules: [(temp) => temp.includes('qi2-wireless-charging')],
      },
      {
        name: 'TravelEssentials',
        templates: <TravelEssentialsOld {...props} />,
        rules: [(temp) => temp.includes('travel-essentials')],
      },
      {
        name: 'HesBrand',
        templates: <HesBrandPage {...props} />,
        rules: [(temp) => temp.includes('hes-brand')],
      },
      {
        name: 'ChristmasDay2023',
        templates: <ChristmasDay2023 {...props} />,
        rules: [(temp) => temp === '/christmas-day-2023'],
      },
      {
        name: 'ChristmasDay2023Au',
        templates: <ChristmasDay2023Au {...props} />,
        rules: [(temp) => temp === '/boxing-day-2023'],
      },
      {
        name: 'MppCampaign2023',
        templates: <MppCampaign2023 {...props} />,
        rules: [
          (target) => target === '/mpp-campaign-2023',
          (target) => target === '/mpp-campaign-2024',
        ],
      },
      {
        name: 'OutdoorCampaign2024',
        templates: <OutdoorCampaign2024 {...props} />,
        rules: [(temp) => temp === '/outdoor-campaign-2024'],
      },
      {
        name: 'HubDocking2024',
        templates: <HubDocking2024 {...props} />,
        rules: [(temp) => temp === '/hub-docking'],
      },
      {
        name: 'A1790',
        templates: <A1790 {...props} />,
        rules: [(temp) => temp.includes('/a1790')],
      },
      {
        name: 'installer',
        templates: <Installer {...props} />,
        rules: [(temp) => temp.includes('/installer')],
      },
      {
        name: 'X1LangdingPage',
        templates: <X1LangdingPage {...props} />,
        rules: [(temp) => temp.includes('/x1-landing-page')],
      },
      {
        name: 'HesForm',
        templates: <HesForm {...props} />,
        rules: [(temp) => temp.includes('/hes-form')],
      },
      {
        name: 'MemberGift',
        templates: <MemberGift {...props} />,
        rules: [(temp) => temp.includes('/member-gift')],
      },
      {
        name: 'EarthDay',
        templates: <EarthDay {...props} />,
        rules: [(temp) => temp === '/earth-day'],
      },
      {
        name: 'AuEarthDay',
        templates: <AuEarthDay {...props} />,
        rules: [(temp) => temp === '/au-earth-day'],
      },
      {
        name: 'A17b1',
        templates: <A17b1 {...props} />,
        rules: [(temp) => temp === '/a17b1'],
      },
      {
        name: 'SeoTopic',
        templates: <SeoTopic {...props} />,
        rules: [(temp) => temp === '/seo-topic'],
      },
      {
        name: '2024SolixEarthDay',
        templates: <SolixEarthDay2024 {...props} />,
        rules: [(temp) => temp === '/solix-earth-day-2024'],
      },
      {
        name: '2024SolixSummerSales',
        templates: <SolixSummerSales2024 {...props} />,
        rules: [(temp) => temp === '/solix-summer-sales-2024'],
      },
      {
        name: '2024OnTheGoCampaign',
        templates: <OnTheGoCampaign2024 {...props} />,
        rules: [(temp) => temp === '/on-the-go-campaign-2024'],
      },
      {
        name: '2024OnTheGoCampaignAU',
        templates: <OnTheGoCampaign2024AU {...props} />,
        rules: [(temp) => temp === '/on-the-go-campaign-2024-au'],
      },
      {
        name: 'BuiltIn',
        templates: <BuiltIn {...props} />,
        rules: [(temp) => temp.includes('/built-in')],
      },
      {
        name: 'Pre17c1',
        templates: <Pre17c1 {...props} />,
        rules: [(temp) => temp === 'pre17c1'],
      },
      {
        name: 'SolarCampaign',
        templates: <SolarCampaign {...props} />,
        rules: [(temp) => temp === 'solar-campaign'],
      },
      {
        name: 'a17c1',
        templates: <A17c1 {...props} />,
        rules: [(temp) => temp === 'a17c1'],
      },
      {
        name: 'MemorialDay2024',
        templates: <MemorialDay2024 {...props} />,
        rules: [(temp) => temp === '/memorial-day-2024'],
      },
      {
        name: 'Promotional',
        templates: <Promotional {...props} />,
        rules: [(temp) => temp === '/promotional'],
      },
      {
        name: 'SEO2024',
        templates: <SEO2024 {...props} />,
        rules: [(temp) => temp === '/seo2024'],
      },
      {
        name: 'ChargerBrand',
        templates: <ChargerBrand {...props} />,
        rules: [(temp) => temp === 'charger-brand'],
      },
      {
        name: 'power-outage',
        templates: <PowerOutage {...props} />,
        rules: [(temp) => temp === '/power-outage'],
      },
      {
        name: 'Voltaic',
        templates: <Voltaic {...props} />,
        rules: [(temp) => temp === '/voltaic'],
      },
      {
        name: 'VoltaicSence',
        templates: <VoltaicSence {...props} />,
        rules: [
          (temp) => {
            return temp === '/photovoltaicscence'
          },
        ],
      },
      {
        name: 'spline-render',
        templates: <SplineRender {...props} />,
        rules: [(temp) => temp === '/spline-render'],
      },
      {
        name: 'shellMembrane',
        templates: <ShellMembrane {...props} />,
        rules: [(temp) => temp === '/shellmembrane'],
      },
      {
        name: 'BlonyUserStory',
        templates: <BlonyUserStory {...props} />,
        rules: [(temp) => temp === '/blonyuserstory'],
      },
      {
        name: 'SolixFans',
        templates: <SolixFans {...props} />,
        rules: [(temp) => temp === '/solix-fans'],
      },
      {
        name: 'ApartmentHES',
        templates: <ApartmentHES {...props} />,
        rules: [(temp) => temp === '/apartment-hes'],
      },
      {
        name: 'SolixFansFinal',
        templates: <SolixFansFinal {...props} />,
        rules: [(temp) => temp === '/solix-fans-final'],
      },
    ])
  }, [metafields?.template, props, slug])
  return <>{template}</>
}

export default Pages
